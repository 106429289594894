import { Component } from "vue-property-decorator";
import AbstractController from "@/controllers/abstracts/AbstractController";

@Component({ name: "CookiesValidation" })
export default class CookiesValidationController extends AbstractController {
  sheet = false;

  get sendMessage() {
    const message = "Olá, preciso de ajuda!";
    return `https://wa.me/+5511996636969?text=${encodeURI(message)}`;
  }

  setCookies(value: any) {
    localStorage.setItem("cookies_checked", JSON.stringify(value));
    this.sheet = false;
  }

  async mountHandler() {
    if (!localStorage.getItem("cookies_checked")) {
      this.sheet = true;
    }
  }
}
