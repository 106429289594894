import ArbiLogo from "@/assets/SVG/logos/logotipo-ciadocredito.png";
import MinimalArbiLogo from "@/assets/SVG/logos/iconciadocredito.png";
import LoginImage from "@/assets/login/ciadocredito.png";
import { IBrand } from "@/types/whitelabel";
import { EMessageSendingType } from "@/constants/MessageSendingTypes";

const ciadocredito: IBrand = {
  flowMessages: {
    maritalStatus: {
      title: "Informe o seu estado civil",
      description:
        "A informação correta do seu estado civil é muito importante para darmos mais agilidade ao seu pedido de empréstimo.",
    },
    naturalness: {
      title: "Informe o seu local de nascimento",
      description: "",
    },
    sameBankAccount: {
      title: "Deseja utilizar a mesma conta que está em seu contra-cheque/holerite?",
      description:
        "Você pode utilizar a mesma conta bancária que consta em seu Holerite (contra-cheque) ou cadastrar uma nova conta bancária para receber o seu financiamento.",
    },
    personalReferences: {
      title: "Informe uma referência pessoal",
      description: "",
    },
    person: {
      title: "Você é uma pessoa politicamente exposta?",
      description: "",
    },
    relationship: {
      title: "Você possui algum relacionamento próximo com uma pessoa politicamente exposta?",
      description: "",
    },
    addressConfirmation: {
      title: "Informações de endereço",
      description: "",
    },
    analysis: {
      title:
        "Iremos realizar a análise final da proposta e em até 1 dia útil, retomaremos com o status. Você poderá acompanhar a proposta por aqui ou pelo e-mail que enviaremos.",
      description: `
          Em caso de dúvidas não hesite em entrar em contato pelo e-mail
          <span class="font-weight-bold"> contato@dinheirow.com.br </span>
        `,
    },
    endorsement: {
      title: `Estamos mais perto do que nunca!`,
      description: `Parabéns! A proposta já foi encaminhada ao banco para a análise final. O prazo para o
          recebimento é em média de 2 dias úteis, podendo sofrer variações em função do banco
          parceiro e da disponibilidade do sistema do governo. Porém não se preocupe, estamos
          acompanhando todo o processo e entraremos em contato após a análise final pelo banco.`,
    },
    finalizedAnalysis: {
      title: "Parabéns!",
      description:
        "Sua análise foi aprovada!\n" +
        "Em caso de dúvida, não hesite em entrar em contato com um dos atendentes.",
    },
    awaitTransfer: () => {
      return {
        title: "Empréstimo aprovado!",
        description: `Seu dinheiro será transferido em breve. Em caso de dúvidas não hesite em entrar em contato pelo e-mail <span class="font-weight-bold">contato@dinheirow.com.br</span>`,
      };
    },
  },
  loginImages: [LoginImage],
  brand: "ciadocredito",
  hideWhatsappIcon: true,
  borrowerCanCreateProposal: false,
  bankOrigin: "ciadocredito",
  pageTitle: "Plataforma Cia Do Credito",
  partnerCode: "partner_cia_do_credito",
  favicon: "iconciadocredito.png",
  logo: { src: ArbiLogo, width: 80, height: 80 },
  logoOnWhite: { src: ArbiLogo, width: 80, height: 80 },
  minimalLogo: { src: MinimalArbiLogo, width: 60, height: 60 },
  loanValues: {
    min: 300,
    max: 60000,
    cardValues: [5000, 10000, 20000, 30000, 50000],
  },
  contactEmail: "contato@ciadocredito.com",
  contactWhatsappNumber: "+5511996636969",
  contactWhatsappMessage: "Olá, preciso de ajuda!",
  colors: {
    primary: "#03408D",
    secondary: "#239088",
    tertiary: "#fdfbeb",
    quaternary: "#1e7b75",
    quinary: "#7ad06d",
    grey: "#7a7a7a",
    "light-grey": "#fafafa",
    "primary-lighten": "#b7edf0",
    "tertiary-lighten": "#fdfbeb",
  },
  logoutUrl: "https://ciadocredito.net/site/home",
  specificRoutes: [
    {
      path: "/",
      name: "Landing",
      component: () => import("@/pages/login/LoginPage.vue"),
      meta: { requiresAuth: false },
    },
    {
      path: "/cadastro",
      name: "Register",
      meta: { requiresAuth: false },
      component: () => import("@/pages/register/RegisterPage.vue"),
      children: [
        {
          path: "arbi",
          name: "RegisterArbi",
          meta: { requiresAuth: false },
          props: { name: "Arbi" },
          component: () => import("@/components/register/Register.vue"),
        },
        {
          path: "contadigital",
          name: "DigitalAccount",
          meta: { requiresAuth: false },
          props: { name: "Conta Digital" },
          component: () => import("@/components/register/Register.vue"),
        },
      ],
    },
  ],
  modalityCodes: [
    "pensionista_inss",
    "militar",
    "servidor_municipal",
    "servidor_federal",
    "servidor_estadual",
    "cdc",
    "modality_fgts",
  ],
  partners: ["motorola"],
  messageSendingType: EMessageSendingType.WhatsApp,
};

export default ciadocredito;
