import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class BankLoanConfigurationResource extends AbstractOnboardResource {
  /**
   *
   * @param proposalId
   * @param includes
   * @return {Promise<ResourceReturnModel>}
   */
  async find({ proposalId, includes }) {
    try {
      const response = await this.api.get({
        route: `/proposals/${proposalId}/bank-loan-configuration`,
        params: { includes },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   * Estrutura de dados representando as configurações de empréstimo bancário.
   *
   * @typedef {Object} BankLoanConfiguration
   * @property {boolean} active - Indica se a configuração está ativa.
   * @property {number} bank_id - ID do banco.
   * @property {number} brand_id - ID da marca.
   * @property {number} covenant_id - ID do convênio.
   * @property {number} cr_percentage - Percentual de CR.
   * @property {number} credit_cut_percentage - Percentual de corte de crédito.
   * @property {null|number} cut_day - Dia de corte.
   * @property {null|number} delay_month - Mês de atraso.
   * @property {number} due_day - Dia de vencimento.
   * @property {number} id - ID da configuração.
   * @property {number} iof_type - Tipo de IOF.
   * @property {Object} loan_category - Categoria do empréstimo.
   * @property {string} loan_category.code - Código da categoria do empréstimo.
   * @property {number} loan_category.id - ID da categoria do empréstimo.
   * @property {string} loan_category.name - Nome da categoria do empréstimo.
   * @property {Object} loan_type - Tipo de empréstimo.
   * @property {string} loan_type.code - Código do tipo de empréstimo.
   * @property {string|null} loan_type.description - Descrição do tipo de empréstimo.
   * @property {string|null} loan_type.help_text - Texto de ajuda do tipo de empréstimo.
   * @property {number} loan_type.id - ID do tipo de empréstimo.
   * @property {string} loan_type.name - Nome do tipo de empréstimo.
   * @property {number} loan_type.order - Ordem do tipo de empréstimo.
   * @property {Object} modality - Modalidade do empréstimo.
   * @property {string} modality.code - Código da modalidade.
   * @property {string|null} modality.description - Descrição da modalidade.
   * @property {string|null} modality.help_text - Texto de ajuda da modalidade.
   * @property {number} modality.id - ID da modalidade.
   * @property {number} modality.loan_feature_id - ID da característica do empréstimo associada à modalidade.
   * @property {string} modality.name - Nome da modalidade.
   * @property {number|null} modality.ordering - Ordem da modalidade.
   * @property {Object} ocr_type - Tipo de OCR.
   * @property {string} ocr_type.cnh - Tipo de CNH no OCR.
   * @property {string} ocr_type.rg - Tipo de RG no OCR.
   * @property {string} ocr_type.selfie - Tipo de selfie no OCR.
   * @property {Object} role - Papel associado à configuração.
   * @property {string} role.code - Código do papel.
   * @property {string|null} role.description - Descrição do papel.
   * @property {string|null} role.help_text - Texto de ajuda do papel.
   * @property {number} role.id - ID do papel.
   * @property {string} role.name - Nome do papel.
   * @property {number} role.ordering - Ordem do papel.
   * @property {number} role_id - ID do papel associado à configuração.
   * @property {null|number} role_subtype_id - ID do subtipo de papel associado à configuração.
   * @property {number} score_minimum - Pontuação mínima.
   * @property {boolean} should_send_messages - Indica se mensagens devem ser enviadas.
   * @property {null|string} template_for - Modelo para.
   * @property {boolean} use_automatic_approvation - Indica se a aprovação automática deve ser usada.
   * @property {boolean} use_block_list - Indica se a lista de bloqueio deve ser usada.
   * @property {boolean} use_pending - Indica se a configuração usa pendências.
   * @property {boolean} use_presumed_income - Indica se a renda presumida deve ser usada.
   */

  /**
   * Obtém as configurações de empréstimo bancário para uma determinada marca.
   *
   * @async
   * @function
   * @param {string | number} brandId - O ID da marca para a qual as configurações de empréstimo bancário estão sendo solicitadas.
   * @returns {Promise<{ data: BankLoanConfiguration[], response: AxiosResponse<any, any>, error: boolean }>} Um objeto Promise que resolve para um objeto contendo informações sobre a resposta da solicitação.
   *  * @throws {Error} Lança um erro se houver um problema ao obter as configurações de empréstimo bancário.
   * @throws {Error} Lança um erro se houver um problema ao obter as configurações de empréstimo bancário.
   *
   * @example
   * const brandId = 'exemploMarcaId';
   * try {
   *   const loanConfigurations = await getBankLoanConfigurations(brandId);
   *   console.log(loanConfigurations);
   * } catch (error) {
   *   console.error(`Erro ao obter configurações de empréstimo bancário: ${error.message}`);
   * }
   */
  async getBankLoanConfigurations(brandId) {
    try {
      const response = await this.api.get({ route: `brands/${brandId}/bank-loan-configurations` });

      return this.newResourceReturnFactory.create({
        response: response,
        data: response?.data?.response,
      });
    } catch (e) {
      return this.newResourceReturnFactory.create({
        response: e,
        data: {},
      });
    }
  }
}
