import AbstractFactory from "@/models/factories/AbstractFactory";
import ResourceReturnModel from "@/models/ResourceReturnModel";

class ResourceReturnFactory extends AbstractFactory {
  /**
   *
   * @param object
   * @param options
   * @return {ResourceReturnModel}
   */
  create({ object = {}, options = {} } = {}) {
    return new ResourceReturnModel({
      error: true,
      data: {},
      responseObject: {},
      ...object,
    });
  }
}

export default new ResourceReturnFactory();
