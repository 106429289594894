import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class AdditionalInformationResource extends AbstractOnboardResource {
  /**
   *
   * @param {object} formData
   * @param {string | number} formData.proposalId
   * @param {string=} formData.cellphone
   * @param {any=} formData.marital_status
   * @param {string=} formData.naturalness
   * @param {string=} formData.phone
   * @param {string=} formData.margin_query_token
   * @return {Promise<ResourceReturnModel>}
   */
  async setAdditionalInformationProposalUser({
    cellphone,
    marital_status,
    naturalness,
    phone,
    proposalId,
    margin_query_token,
  }) {
    try {
      const response = await this.api.put({
        route: `/proposals/${proposalId}/users`,
        payload: { cellphone, marital_status, naturalness, phone, margin_query_token },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }
}
