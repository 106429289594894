// import { AxiosResponse } from "axios";
// export interface IResourceReturnModel {
//   error: boolean;
//   data: {} | [];
//   responseObject: AxiosResponse;
// }

/**
 * @class
 * @property {boolean} error
 * @property {any} data
 * @property {AxiosResponse} responseObject
 */
export default class ResourceReturnModel {
  // public error: boolean;
  // public data: object | [];
  // public responseObject: AxiosResponse;

  constructor({ error, data, responseObject }) {
    this.error = error;
    this.data = data;
    this.responseObject = responseObject;
  }
}
