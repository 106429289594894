import { IBrand } from "@/types/whitelabel";
import { EMessageSendingType } from "@/constants/MessageSendingTypes";
import defaultLoginImage from "@/assets/images/genial_login.png";
import bancoGenialLogo from "@/assets/SVG/logos/bancogenial.png";

const bancoGenial: IBrand = {
  baseUrl: "/",
  brand: "bancogenial",
  bankOrigin: "arbi",
  loginImages: [defaultLoginImage],
  partnerCode: "partner_genial",
  pageTitle: "Plataforma Banco Genial",
  favicon: "bancogenial.ico",
  minimalLogo: { src: bancoGenialLogo, height: 100, width: 100 },
  logo: { src: bancoGenialLogo, width: 100, height: 100 },
  logoOnWhite: { src: bancoGenialLogo, height: 120, width: 120 },
  loanValues: {
    min: 1000,
    max: 10000,
    cardValues: [1000, 2000, 3000, 4000, 5000],
  },
  contactWhatsappNumber: "+5511996636969",
  contactWhatsappMessage: "Olá, preciso de ajuda!",
  colors: {
    primary: "#009CFF",
    secondary: "#273495",
    tertiary: "#FFf",
    quaternary: "#00717A",
    quinary: "#38E5C4",
    grey: "#7A7A7A",
    "light-grey": "#fafafa",
    "primary-lighten": "#b7edf0",
    "tertiary-lighten": "#fdfbeb",
  },
  logoutUrl: "https://www.bancogenial.com/",
  modalityCodes: ["modality_fgts"],
  hideWhatsappIcon: true,
  messageSendingType: EMessageSendingType.SMS,
  flowMessages: {
    maritalStatus: {
      title: "Informe o seu estado civil",
      description:
        "A informação correta do seu estado civil é muito importante para darmos mais agilidade ao seu pedido de empréstimo.",
    },
    naturalness: {
      title: "Informe o seu local de nascimento",
      description: "",
    },
    sameBankAccount: {
      title: "Deseja utilizar a mesma conta que está em seu contra-cheque/holerite?",
      description:
        "Você pode utilizar a mesma conta bancária que consta em seu Holerite (contra-cheque) ou cadastrar uma nova conta bancária para receber o seu financiamento.",
    },
    personalReferences: {
      title: "Informe uma referência pessoal",
      description: "",
    },
    person: {
      title: "Você é uma pessoa politicamente exposta?",
      description: "",
    },
    relationship: {
      title: "Você possui algum relacionamento próximo com uma pessoa politicamente exposta?",
      description: "",
    },
    addressConfirmation: {
      title: "Informações de endereço",
      description: "",
    },
    analysis: {
      title:
        "Iremos realizar a análise final da proposta e em até 1 dia útil, retomaremos com o status. Você poderá acompanhar a proposta por aqui ou pelo e-mail que enviaremos.",
      description: `
          Em caso de dúvidas não hesite em entrar em contato pelo e-mail
          <span class="font-weight-bold"> contato@dinheirow.com.br </span>
        `,
    },
    endorsement: {
      title: `Estamos mais perto do que nunca!`,
      description: `Parabéns! A proposta já foi encaminhada ao banco para a análise final. O prazo para o
          recebimento é em média de 2 dias úteis, podendo sofrer variações em função do banco
          parceiro e da disponibilidade do sistema do governo. Porém não se preocupe, estamos
          acompanhando todo o processo e entraremos em contato após a análise final pelo banco.`,
    },
    awaitTransfer: () => {
      return {
        title: "Empréstimo aprovado!",
        description: `Seu dinheiro será transferido em breve. Em caso de dúvidas não hesite em entrar em contato pelo e-mail <span class="font-weight-bold">contato@dinheirow.com.br</span>`,
      };
    },
  },

  borrowerCanCreateProposal: false,

  contactEmail: "contato@bancogenial.com.br",

  specificRoutes: [
    {
      path: "/",
      name: "Landing",
      component: () => import("@/pages/login/LoginPage.vue"),
      meta: { requiresAuth: false },
    },
    {
      path: "/cadastro",
      name: "Register",
      meta: { requiresAuth: false },
      component: () => import("@/pages/register/RegisterPage.vue"),
      children: [
        {
          path: "arbi",
          name: "RegisterArbi",
          meta: { requiresAuth: false },
          props: { name: "Arbi" },
          component: () => import("@/components/register/Register.vue"),
        },
        {
          path: "contadigital",
          name: "DigitalAccount",
          meta: { requiresAuth: false },
          props: { name: "Conta Digital" },
          component: () => import("@/components/register/Register.vue"),
        },
      ],
    },
  ],

  partners: ["motorola"],
};

export default bancoGenial;
